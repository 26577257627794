export const DATE_FORMAT = 'DD.MM.YYYY'

export const DATE_MASK = '__.__.____'

export const TIME_FORMAT = 'HH:mm'

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'

export const UNIX_FORMAT = 'X'

export const MAX_DAYS = 7
export const MAX_BOOKING_TIME = 24
export const MAX_UNBOOKING_TIME = 24

export const ADMIN_PASSWORD = '1307'

export enum ERegions {
    all = 'all',
    moscow = 'moscow',
    kolomna = 'kolomna',
    pafos = 'pafos',
    limassol = 'limassol',
    other = 'other',
}

export enum ECountry {
    RU = 'RU',
    CY = 'CY',
}

export const SERVICES_ITEMS: IServiceItem[] = [
    {
        id: 'label_brows',
        type: 'label',
        name: 'Брови',
    },
    {
        id: 'brows_styling',
        type: 'box',
        name: 'Долговременная укладка бровей',
        disableIf: ['brows_henna'],
        priceId: 1,
    },
    {
        id: 'brows_paint',
        type: 'box',
        name: 'Коррекция и окрашивание краской',
        disableIf: ['brows_henna'],
        priceSummWith: ['brows_styling'],
        priceId: 2,
    },
    {
        id: 'brows_henna',
        type: 'box',
        name: 'Коррекция и окрашивание хной',
        disableIf: ['brows_paint', 'brows_styling'],
        priceId: 3,
    },
    {
        id: 'brows_other',
        type: 'box',
        name: 'Другое (укажите в комментариях)',
    },
    {
        id: 'label_add',
        type: 'label',
        name: 'Дополнительно',
    },
    {
        id: 'eyelashes_paint',
        type: 'box',
        name: 'Окрашивание ресниц',
        priceId: 4,
    },
]

interface IServiceItem {
    id: string
    type: string
    name: string
    disableIf?: string[]
    priceSummWith?: string[]
    priceId?: number
}

/* export const COLORS_2 = {
    mainBack: '#fff5f6',
    mainBackHover: '#ffe6e9',
    mainColor: '#fa977f',

    secondBack: '#ffcccf',
    secondBackHover: '#ffa1a7',
    secondColor: '#fff',

    submitBack: '#13abb7',
    submitBackHover: '#0e7a81',
    submitColor: '#fff',
}; */

export const COLORS = {
    light: '#fff',
    lightHover: '#f9f9f9',
    dark: '#333',
    colorBrown: '#c7ae8f',
    colorBrown2: '#fff3e5',
    colorBrownHover: '#937a5a',

    colorPink: '#fdecee',
    colorPinkHover: '#efccd0',
}

export const METHODS = {
    noMatter: 'Не важно',
    phone: 'Телефон',
    whatsApp: 'WhatsApp',
    telegram: 'Telegram',
}

export const REGIONS: Record<ERegions, string> = {
    all: 'Все',
    moscow: 'Москва',
    kolomna: 'Коломна',
    pafos: 'Пафос',
    limassol: 'Лимассол',
    other: 'Другой',
}

export const SHORT_REGIONS: Record<ERegions, string> = {
    all: 'Все',
    moscow: 'Мск',
    kolomna: 'Клмн',
    pafos: 'Паф',
    limassol: 'Лим',
    other: 'Др',
}

export const IMAGES = {
    wedding: 'Свадебный образ',
    evening: 'Вечерний образ',
    other: 'Другое',
}

export enum EDbTables {
    hour = 'hour',
    booking = 'booking',
    setting = 'setting',
    image = 'image',
    telegram = 'telegram',
    portfolio = 'portfolio',
    feedback = 'feedback',
}

export enum ESettings {
    maxDays = 'maxDays',
    maxBookingTime = 'maxBookingTime',
    maxUnbookingTime = 'maxUnbookingTime',
    priceList = 'priceList',
}

export enum EMethods {
    noMatter = 'noMatter',
    phone = 'phone',
    whatsApp = 'whatsApp',
    telegram = 'telegram',
}

export enum EImages {
    wedding = 'wedding',
    evening = 'evening',
    other = 'other',
}

export enum EBookingTypes {
    brows = 'brows',
    image = 'image',
    training = 'training',
}

export enum EPriceListType {
    brows = 'brows',
    image = 'image',
    wedding = 'wedding',
}

export type TSQLiteSchema = {
    [key: string]: 'INTEGER' | 'REAL' | 'TEXT' | 'BLOB'
}

export type IDBSetting = {
    id?: number
    name: string
    value: string
}

export interface IDBTelegram {
    id?: number
    user_id: number
    type: string
}

export type IDBHour = {
    id?: number
    date: number
    interval: string
    region: ERegions
}

export type IDBBooking = {
    id?: number
    date?: number
    region: ERegions
    name: string
    surname: string
    phone: string
    comment: string
    type: EBookingTypes
    interval?: string
    method?: EMethods
    services?: string
    imageType?: EImages
}

export interface ISettings {
    [ESettings.maxDays]?: number
    [ESettings.maxBookingTime]?: number
    [ESettings.maxUnbookingTime]?: number
    [ESettings.priceList]?: IPriceLists
}

export type IPriceListItem = Record<EPriceListType, Record<string, IService[]>>
export type IPriceLists = Record<ECountry, IPriceListItem>

export interface IHour extends Omit<IDBHour, 'interval'> {
    interval: IInterval
}

export interface IBooking extends Omit<IDBBooking, 'interval' | 'services'> {
    interval?: IInterval
    services?: string[]
}

export interface IInterval {
    from: string
    to: string
}

export interface IDateIntervals {
    date: number
    region: ERegions
    intervals: IInterval[]
}

export interface IDateInterval {
    date: number
    region: ERegions
    interval: IInterval
}

export interface IUser {
    isAdmin: boolean
    phone: string
}

export interface IError {
    message: string
    code?: string
}

export type IImageData = {
    id?: number
    image: Buffer
    sImage: Buffer
    portfolio: number
}

export interface IDBPortfolio {
    id?: number
    name: string
    orderId: number
    images: string
}

export interface IPortfolio extends Omit<IDBPortfolio, 'images'> {
    images: number[]
}

export interface IService {
    id: number
    name: string
    nameLevel2?: string
    nameLevel3?: string
    price: string
    duration?: string
    includes?: string[]
    comments?: string[]
}

export type IFeedback = {
    id?: number
    date?: number
    name: string
    phone: string
    comment: string
}
