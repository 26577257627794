import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import {ESettings} from 'shared/consts'
import {useSetSettings, useSettings} from 'front/queries/settings'
import {useIsAdmin, useSetUser} from 'front/queries/user'

import styles from './adminPage.css'

export function AdminPage() {
    const [pass, setPass] = useState<string>('')
    const {data: settings} = useSettings()

    const [maxDays, setMaxDays] = useState<string>('')
    const [maxBookingTime, setMaxBookingTime] = useState<string>('')
    const [maxUnbookingTime, setMaxUnbookingTime] = useState<string>('')

    const navigate = useNavigate()

    const isAdmin = useIsAdmin()
    const {mutate: setUser} = useSetUser()
    const {mutate: setSettings} = useSetSettings()

    useEffect(() => {
        if (!settings) {
            return
        }

        setMaxDays(String(settings[ESettings.maxDays]))
        setMaxBookingTime(String(settings[ESettings.maxBookingTime]))
        setMaxUnbookingTime(String(settings[ESettings.maxUnbookingTime]))
    }, [settings])

    if (!isAdmin) {
        return (
            <Stack className={styles.adminPage} spacing={2} direction='row'>
                <TextField
                    label='Password'
                    variant='outlined'
                    size='small'
                    value={pass}
                    onChange={e => {
                        setPass(e.target.value)
                    }}
                />
                <Button
                    className={styles.button}
                    variant='contained'
                    onClick={() => {
                        setUser(pass)
                    }}
                >
                    Войти
                </Button>
            </Stack>
        )
    }

    return (
        <Stack className={styles.adminPage} spacing={3}>
            <Stack spacing={2}>
                <div className={styles.header}>Запись на</div>
                <div className={styles.buttonsWrap}>
                    <Button
                        className={styles.button}
                        color='primary'
                        variant='contained'
                        onClick={async () => {
                            navigate('/admin/image')
                        }}
                    >
                        Образ
                    </Button>
                    <Button
                        className={styles.button}
                        color='primary'
                        variant='contained'
                        onClick={async () => {
                            navigate('/booking/brows')
                        }}
                    >
                        Брови
                    </Button>
                    <Button
                        className={styles.button}
                        color='primary'
                        variant='contained'
                        onClick={async () => {
                            navigate('/admin/training')
                        }}
                    >
                        Обучение
                    </Button>
                </div>
            </Stack>
            <Stack spacing={2}>
                <div className={styles.header}>Разное</div>
                <div className={styles.buttonsWrap}>
                    <Button
                        className={styles.button}
                        color='primary'
                        variant='contained'
                        onClick={async () => {
                            navigate('/admin/feedback')
                        }}
                    >
                        Обратная связь
                    </Button>
                    <Button
                        className={styles.button}
                        color='primary'
                        variant='contained'
                        onClick={async () => {
                            navigate('/admin/portfolio')
                        }}
                    >
                        Портфолио
                    </Button>
                    <Button
                        className={styles.button}
                        color='primary'
                        variant='contained'
                        onClick={async () => {
                            navigate('/admin/price')
                        }}
                    >
                        Прайс-лист
                    </Button>
                    <Button
                        className={styles.button}
                        color='primary'
                        variant='contained'
                        onClick={async () => {
                            navigate('/memo')
                        }}
                    >
                        Памятка
                    </Button>
                </div>
            </Stack>
            <Stack spacing={2}>
                <div className={styles.header}>Настройки</div>
                <TextField
                    required
                    label='Количество дней'
                    variant='outlined'
                    size='small'
                    value={maxDays}
                    inputProps={{
                        inputMode: 'numeric',
                    }}
                    onChange={e => {
                        setMaxDays(e.target.value)
                    }}
                />
                <TextField
                    label='Часов до записи'
                    variant='outlined'
                    size='small'
                    value={maxBookingTime}
                    inputProps={{
                        inputMode: 'numeric',
                    }}
                    onChange={e => {
                        setMaxBookingTime(e.target.value)
                    }}
                />
                <TextField
                    label='Часов до отмены'
                    variant='outlined'
                    size='small'
                    value={maxUnbookingTime}
                    inputProps={{
                        inputMode: 'numeric',
                    }}
                    onChange={e => {
                        setMaxUnbookingTime(e.target.value)
                    }}
                />
                <Button
                    className={styles.button}
                    color='primary'
                    variant='contained'
                    onClick={async () => {
                        setSettings(
                            {
                                [ESettings.maxDays]: Number(maxDays),
                                [ESettings.maxBookingTime]: Number(maxBookingTime),
                                [ESettings.maxUnbookingTime]: Number(maxUnbookingTime),
                            },
                            {
                                onSuccess: () => {
                                    navigate('/')
                                },
                            },
                        )
                    }}
                >
                    Сохранить
                </Button>
                <Button
                    className={styles.button}
                    color='primary'
                    variant='outlined'
                    onClick={async () => {
                        setUser('', {
                            onSuccess: () => {
                                navigate('/')
                            },
                        })
                    }}
                >
                    Выйти
                </Button>
            </Stack>
        </Stack>
    )
}
